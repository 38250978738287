import { Typography, Button, Grid } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";

function StaffAccountHeader({ onCreateStaffClicked }) {
  const user = {
    name: "User Accounts for PeonyKids",
    avatar: "/static/images/avatars/1.jpg",
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          User
        </Typography>
        <Typography variant="subtitle2">
          {user.name}, these are your recent user accounts
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          onClick={ onCreateStaffClicked }
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          Create Portal user
        </Button>
      </Grid>
    </Grid>
  );
}

export default StaffAccountHeader;
