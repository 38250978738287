import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Attributes from './content/applications/DailyReport';
import Approve from './content/applications/ApproveReport';
import StaffAccount from './content/applications/StaffAccount';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications


const KidsTable = Loader(lazy(() => import('src/content/applications/KidsComponent')));
const ParentsTable = Loader(lazy(() => import('src/content/applications/ParentComponent')));
const Login = Loader(lazy(() => import('src/content/pages/Status/Login')));
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const QRContent = Loader(
  lazy(() => import('src/content/pages/Status/Others/QRContent'))
);

interface RouterParams {
  to: string;
  element: React.ReactNode;
}

const AdminRoutes: RouterParams[] = [
  {
    to: 'daily-report',
    element: <Attributes />
  }, {
    to: 'approved-report',
    element: <Approve />
  },{
    to: 'kids',
    element: <KidsTable />
  },
  {
    to: 'parents',
    element: <ParentsTable />
  },
  {
    to: 'qr',
    element: <QRContent />
  },{
   to: 'staff',
    element: <StaffAccount />
  }
];

// Check if the localStorage is set
const accessToken = localStorage.getItem('accessToken');

// @ts-ignore
const routes: RouteObject[] = [
  {
    path: '',
    element: accessToken === null ? <Login /> : <SidebarLayout />,
    children: [
      {
        path: '*',
        element: <Login />
      },
      ...AdminRoutes.map((route) => ({
        path: accessToken === null ? '*' : route.to,
        element: accessToken === null ? <Login /> : route.element
      }))
    ]
  },
  {
    path: 'login',
    element: <Status500 />
  },{
      path: 'qr-content',
      element: <QRContent/>
  }
];

export default routes;
