import React, { FC, useState, useEffect } from 'react';
import {
  Divider,
  Card,
  TableCell,
  TableRow,
  TableContainer
} from '@mui/material';
import { API } from '../../../config/config';
import {
  DynamicTable,
  TableSearch
} from '../../../components/DynamicTable/DynamicTable';

import { descendingComparator } from '../../../Utils';

interface RecentOrdersTableProps {
  className?: string;
}

const StaffAccountContent: FC<RecentOrdersTableProps> = (props) => {
  const [staffAccount, setStaffAccount] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [sortBy, SetSortBy] = useState<string>('id');
  const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    fetch(`${API.HOST}portal/users/`, {
      // 1. Change the URL
      method: 'GET',
      headers: {
        Authorization: null
      }
    }).then(async (response) => {
      const data = await response.json();
      setStaffAccount(
        data.map((attribute: any) => {
          return {
            id: attribute.id,
            role: attribute.role,
            firstName: attribute.firstName,
            lastName: attribute.lastName,
            phoneNumber: attribute.phoneNumber,
            email: attribute.email
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    const _staffAccounts = staffAccount.sort((a, b) => {
      if (sortAsc === 'asc') {
        return descendingComparator(a, b, sortBy);
      }
      if (sortAsc === 'desc') {
        return -descendingComparator(a, b, sortBy);
      }
    });
    setStaffAccount(_staffAccounts);
  }, [sortBy, sortAsc]);

  return (
    <Card>
      <Divider />
      <TableContainer>
        <DynamicTable
          sortByField={sortBy}
          onSort={(headerProperty) => {
            SetSortBy(headerProperty);
            setSortAsc(sortAsc === 'asc' ? 'desc' : 'asc');
          }}
          sort={sortAsc}
          searchElement={
            <TableSearch
              placeholder={'Search Staffs'}
              searchData={staffAccount}
              setSearchData={setSearchData}
            />
          }
          headers={[
            {
              displayName: 'First Name',
              key: 'firstName'
            },
            {
              displayName: 'Last Name',
              key: 'lastName'
            },
            {
              displayName: 'Phone Number',
              key: 'phoneNumber'
            },
            {
              displayName: 'Email',
              key: 'email'
            }
          ]}
        >
          {(searchData || staffAccount).map((row: any, index: number) => {
            return (
              <TableRow key={index}>
                <TableCell key={index}>{row.firstName}</TableCell>
                <TableCell key={index}>{row.lastName}</TableCell>
                <TableCell key={index}>{row.phoneNumber}</TableCell>
                <TableCell key={index}>{row.email}</TableCell>
              </TableRow>
            );
          })}
        </DynamicTable>
      </TableContainer>
    </Card>
  );
};

StaffAccountContent.propTypes = {};

StaffAccountContent.defaultProps = {};

export default StaffAccountContent;
