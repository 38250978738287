import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box, Button, InputLabel, Menu,
  MenuItem, Select, TextField, Autocomplete, Chip
} from '@mui/material';


import { styled } from '@mui/material/styles';

export interface PrizetAttributeSelectorProps {
  label: string;
  name: string;
  value: string;
  options: [
    {
    value:string;
    label:string;
    attributeValues:[{id:string, name:string}]
    }]
  onAttributeEvent: (any) => void;


}

export interface PrizetAttributeSelectorPayload {
  attributeId: string;
  value: [];
  options?: any[];
}

export interface PrizetAttributeAPI {
  data: PrizetAttributeSelectorPayload[];
}

export const PrizetAttributeSelector = (props: PrizetAttributeSelectorProps) => {
  const [attributeName, setAttributeName] = useState<string>('');
  const [attributesItem, setAttributesItem] = useState<string | string[]>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { label, value, options, name, onAttributeEvent } = props;
  const [attributeList, setAttributeList] =
    useState<any[]>([]);

  console.log(options);




  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    setAnchorEl(null);
    const attributeType = event.target.textContent;
    if(attributeType === 'Text'){
      setAttributeList([...attributeList,{
        value:null,
        attributeId:"",
        options:[]
      }])
    }

  };

  const onUpdate = () => {

  };


  return (
    <>
      {attributeList.map((attribute, attributeIndex) => {
        return (
          <div key={attributeIndex}>
            <Box sx={
              {
                marginTop: '5%',
                marginBottom: '5%'
              }
            }>

              <Box
                flexDirection={'row'}
                columnGap={4}
                overflow={'scroll-y'}
                display={'flex'}>
                <Select
                  placeholder={label}
                  onChange={(e) => {
                    attribute.attributeId= e.target.value
                    attributeList[attributeIndex] = attribute;
                    // get the options by label
                    const selectedOption =
                      options.filter(option => option.value === e.target.value);
                    attributeList[attributeIndex].options = selectedOption[0].attributeValues;
                    attributeList[attributeIndex].value = [];
                    setAttributeList([...attributeList])
                  }}
                  labelId="label"
                  id="select"
                  value={attribute.attributeId}
                  sx={{
                    width: '50%'
                  }}
                >
                  {options?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  multiple={true}
                  placeholder={label}
                  onChange={(e) => {
                    attribute.value= e.target.value as any;
                    attribute.selected = true;
                    attributeList[attributeIndex] = attribute;
                    // current attribute

                    setAttributeList([...attributeList])
                    onAttributeEvent(attributeList);
                  }}
                  labelId="label"
                  id="select"
                  value={attribute.value}
                  sx={{
                    width: '50%'
                  }}
                >
                  {attribute?.options?.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>

              </Box>

            </Box>
          </div>
        );
      })}
      <Box
        sx={{
          marginTop: '2%'
        }}
        justifyContent={'right'}
        display={'flex'}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Add Attribute
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={handleClose}>Text</MenuItem>
          <MenuItem onClick={handleClose}>Existing Attribute</MenuItem>
        </Menu>
      </Box>
    </>

  );
};
const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75'
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025'
};

const Listbox = styled('ul')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 6px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
  };
  z-index: 1;
  `
);