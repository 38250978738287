import { create } from 'zustand';

interface TokenStore {
  token: string;
}

let token: TokenStore = {
  token: localStorage.getItem('accessToken') || ''
};

export interface TokenState {
  actions: { setToken: (token: TokenStore) => void };
  token: TokenStore;
}

export const useTokenStore = create<TokenState>((set) => ({
  token,
  actions: {
    setToken: (settings: TokenStore) => {
      // @ts-ignore
      set((state) => ({
        token: state.token
      }));
    }
  }
}));
