import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { HashRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;

  config.headers['Authorization'] = `Bearer ${localStorage.getItem(
    'accessToken'
  )}`;

  const response = await originalFetch(resource, config);

  // response interceptor here
  return response;
};

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <HashRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <App />
        </LocalizationProvider>
      </HashRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
