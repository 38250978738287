import { create } from 'zustand';
import { AdminEntity } from '../interfaces/interfaces';

interface AdminStore{
  data?: AdminEntity;
}
let _data: AdminEntity = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  role: '',
  enabled: false,
  verified: false
};
try{
  _data = JSON.parse(localStorage.getItem('adminDetails') || '{}');
}
catch(err){
console.log("Failed to decode the token from the localstorage");
}

let data: AdminStore = {
  data: _data
};

export interface AdminState {
  actions: { setAdmin: (data: AdminStore) => void };
  data: AdminStore;
}

export const useAdminStore = create<AdminState>((set) => ({
  data,
  actions: {
    setAdmin: (settings: AdminStore) => {
      // @ts-ignore
      localStorage.setItem('adminDetails', JSON.stringify(settings.data));
      set((state) => ({
        data: {...state.data, ...settings.data}
      }));
    }
  }
}));
