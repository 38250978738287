import { Typography, Button, Grid } from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useModalStore } from '../../../zustandStore/store.attributes';

function PageHeader() {
  const store = useModalStore((state) => state);
  const action = store.actions;

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Approved Reports
        </Typography>
        <Typography variant="subtitle2">
         These are your Approved reports
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          onClick={(e) =>
            action.setModal({
              modalOpen: true
            })
          }
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          Create Attribute
        </Button>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
