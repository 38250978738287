import { create } from 'zustand';

interface ModalStore {
  modalOpen: boolean;
}

let settings: ModalStore = {
  modalOpen: false
};

export interface ModalState {
  actions: { setModal: (settings: ModalStore) => void };
  settings: ModalStore;
}

export const useModalStore = create<ModalState>((set) => ({
  settings,
  actions: {
    setModal: (settings: ModalStore) => {
      // @ts-ignore
      set((state) => ({
        settings: { ...state.settings, ...settings }
      }));
    }
  }
}));
