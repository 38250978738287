import StaffAccountHeader from './StaffAccountHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Card } from '@mui/material';
import Footer from 'src/components/Footer';
import React, { useState } from 'react';
import { API } from '../../../config/config';
import { HttpModal } from '../../../components/HttpModal/HttpModal';
import StaffAccountContent from './StaffAccountContent';

type StaffComponentState = {
  openModal: boolean;
  password: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  confirmPassword: string;
};

function StaffAccountComponent() {
  const [state, setState] = useState<StaffComponentState>({
    openModal: false,
    firstName: '',
    lastName: '',
    password: '',
    role: '',
    email: '',
    phoneNumber: '',
    confirmPassword: ''
  });
  const [error, setError] = useState<string>('');

  const showCreateStaffModal = () => {
    setState({ ...state, openModal: true });
  };

  return (
    <>
      <title>Staff Account - PeonyKids</title>
      <PageTitleWrapper>
        <StaffAccountHeader onCreateStaffClicked={showCreateStaffModal} />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <StaffAccountContent />
            </Card>
          </Grid>
        </Grid>
        <HttpModal
          width={500}
          error={error}
          maxWidth={'lg'}
          submitText={'Submit'}
          onClose={() => {
            setState({ ...state, openModal: false });
          }}
          onSubmitCallback={async (response) => {
            const json = await response.json();
            console.log(response);
            if (response.status === 201 || response.status === 200) {
              setState({ ...state, openModal: false });
              setError(null);
              window.location.reload();
            }
            if (response.status === 400 || response.status === 404) {
              setError(json.message);
            }
          }}
          openModal={state.openModal}
          fields={[
            {
              type: 'column',
              children: [
                {
                  type: 'text',
                  label: 'First Name',
                  placeholder: '',
                  name: 'firstName',
                  required: true,
                  disabled: false,
                  value: state.firstName,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, firstName: e.target.value });
                  }
                },
                {
                  type: 'text',
                  label: 'Last Name',
                  placeholder: '',
                  name: 'lastName',
                  required: true,
                  disabled: false,
                  value: state.lastName,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, lastName: e.target.value });
                  }
                },
                {
                  type: 'text',
                  label: 'Email',
                  placeholder: '',
                  name: 'email',
                  required: true,
                  disabled: false,
                  value: state.email,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, email: e.target.value });
                  }
                },
                {
                  type: 'phone',
                  label: 'Phone Number',
                  placeholder: '',
                  name: 'phoneNumber',
                  required: true,
                  disabled: false,
                  value: state.phoneNumber,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, phoneNumber: e.target.value });
                  }
                },
                {
                  type: 'select',
                  label: 'Role',
                  placeholder: '',
                  name: 'role',
                  required: true,
                  disabled: false,
                  selectOptions: [
                    { value: 'ADMIN', label: 'Admin' },
                    { value: 'CAREGIVER', label: 'Care giver' },
                    { value: 'MANAGER', label: 'Manager' }
                  ],
                  value: state.role,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, role: e.target.value });
                  }
                },
                {
                  type: 'password',
                  label: 'Password',
                  placeholder: '',
                  name: 'password',
                  required: true,
                  disabled: false,
                  value: state.password,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, password: e.target.value });
                  }
                },
                {
                  type: 'password',
                  label: 'Confirm Password',
                  placeholder: '',
                  name: 'confirmPassword',
                  required: true,
                  disabled: false,
                  value: state.confirmPassword,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, confirmPassword: e.target.value });
                  }
                }
              ]
            }
          ]}
          baseUrl={`${API.HOST}portal/users/create`}
          method={'POST'}
          headers={{
            'Content-type': 'application/json'
          }}
          title={'Create a portal user'}
        />
      </Container>
      <Footer />
    </>
  );
}

export default StaffAccountComponent;
