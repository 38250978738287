import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { DeleteDialog } from './components/DynamicTable/DynamicTable';

declare var fetch;

// Monkey Patch

const responsFunc = Response.prototype.json;

Response.prototype.json = async function(){
  try{
    const data = await responsFunc.call(this);
    return data;
  }
  catch(err){

      if(this.status === 401 || this.status === 403){

        alert('You are not authorized to carry out ths action.')
      }


  }







}

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <DeleteDialog />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
