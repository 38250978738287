import React, { FC, ChangeEvent, useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Modal,
  DialogContent,
  Input,
  TextField
} from '@mui/material';

import Label from 'src/components/Label';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { useModalStore } from '../../../zustandStore/store.attributes';
import { HttpModal } from '../../../components/HttpModal/HttpModal';
import CloseIcon from '@mui/icons-material/Close';
import { API } from '../../../config/config';
import { ActionCell, DynamicTable, TableSearch } from '../../../components/DynamicTable/DynamicTable';
import { useTokenStore } from '../../../zustandStore/store.token';
import { PeonyKid } from '../../../interfaces/interfaces';
import { PeonyModalActivities } from '../../../components/PeonyModal/PeonyModal';
import { Child, PendingReports, Report } from '../../../interfaces/PendingReports';
import { descendingComparator } from '../../../Utils';

interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: CryptoOrder[];
}

interface Filters {
  status?: CryptoOrderStatus;
}

const getStatusLabel = (cryptoOrderStatus: CryptoOrderStatus): JSX.Element => {
  const map = {
    failed: {
      text: 'Failed',
      color: 'error'
    },
    completed: {
      text: 'Completed',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[cryptoOrderStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  cryptoOrders: CryptoOrder[],
  filters: Filters
): CryptoOrder[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    if (filters.status && cryptoOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  cryptoOrders: CryptoOrder[],
  page: number,
  limit: number
): CryptoOrder[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ cryptoOrders }) => {
  const [modalName, setModalName] = useState('');
  const state = useModalStore((state) => state);
  const action = state.actions;
  const dynamicTable = useRef<any>();
  const [retry, setRetry] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [report, setReport] = useState<Report[]>([]);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const [selectedKid, setSelectedKid] = useState<Child | null>(null);

  const token = useTokenStore(state => state.token);
  const [reportDialog, setReportDialog] = useState(false);
  const [sortBy, SetSortBy] = useState<string>('id');
  const [sortAsc, setSortAsc] = useState<'asc' | 'desc'>('asc');


  useEffect(() => {
    if(!reportDialog){
      setSelectedKid(null);
    }
  },[reportDialog])

  useEffect(() => {
    fetch(`${API.HOST}${API.ENDPOINT.APPROVED_REPORTS}`, { // 1. Change the URL
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.token}`
      }
    }).then((response) => {
      response.json().then((data: PendingReports) => {
        setReport(data.data || []);
      });
    });
  }, [retry]);

  useEffect(() => {
    const _reports = report.sort((a, b) => {
      if (sortAsc === 'asc') {
        return descendingComparator(a, b, sortBy);
      }
      if (sortAsc === 'desc') {
        return -descendingComparator(a, b, sortBy);
      }
    });
    setReport(_reports);
  }, [sortBy, sortAsc]);

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'firstName',
      name: 'First Name'
    },
    {
      id: 'lastName',
      name: 'Last Name'
    },
    {
      id: 'gender',
      name: 'Gender'
    },
    {
      id: 'date',
      name: 'Report Date'
    }
  ];
  const showReport = (row: Child) => {
    setSelectedKid(row);
    setReportDialog(true);
  }

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllCryptoOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedCryptoOrders(
      event.target.checked
        ? cryptoOrders.map((cryptoOrder) => cryptoOrder.id)
        : []
    );
  };

  const handleSelectOneCryptoOrder = (
    event: ChangeEvent<HTMLInputElement>,
    cryptoOrderId: string
  ): void => {
    if (!selectedCryptoOrders.includes(cryptoOrderId)) {
      setSelectedCryptoOrders((prevSelected) => [
        ...prevSelected,
        cryptoOrderId
      ]);
    } else {
      setSelectedCryptoOrders((prevSelected) =>
        prevSelected.filter((id) => id !== cryptoOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #d8d8d8',
    boxShadow: 24,
    borderRadius: '5px',
    p: 4
  };

  return (
    <Card>
      <HttpModal
        onClose={() => {
          action.setModal({ modalOpen: false });
        }}
        onSubmitCallback={(response) => {
          if (response.status > 200 && response.status < 300) {
            action.setModal({ modalOpen: false });

          }

          if (response.status > 400) {
            console.log('Error');
          }
        }}
        openModal={state.settings.modalOpen}
        fields={[
          {
            type: 'text',
            label: 'Attribute Name',
            placeholder: 'Attribute Name',
            name: 'attributeName',
            required: true,
            disabled: false,
            value: modalName,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setModalName(e.target.value);
            }
          }
        ]}
        baseUrl={`${API.HOST}api/attributes`}
        method={'POST'}
        headers={{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API.TOKEN}`
        }}
        title={'Attribute'}
      />
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Kids"
        />
      )}
      <Divider />
      {selectedKid && (
        <PeonyModalActivities kidId={selectedKid.id} name={`${selectedKid.firstName} ${selectedKid.lastName}`} open={reportDialog} onClose={() => setReportDialog(false)} />
      )}

      <TableContainer>
        <DynamicTable
          sortByField={sortBy}
          onSort={(headerProperty) => {
            SetSortBy(headerProperty);
            setSortAsc(sortAsc === 'asc' ? 'desc' : 'asc');
          }}
          sort={sortAsc}
          searchElement = {<TableSearch placeholder={"Search Attributes"} searchData={report} setSearchData={setSearchData} />}
          headers={[
            {
              key: 'all',
              displayName: 'All'
            },
            {
              key: 'firstName',
              displayName: 'First Name'
            },
            {
              key: 'lastName',
              displayName: 'Last Name'
            },
            {
              key: 'date',
              displayName: 'Report Date'
            }
        ]}
        >
          {(searchData || report).map((row: Report, index: number) => {
            return (
              <TableRow key={'tr'+index}>
                <TableCell width={"5%"} key={'td'+index}>
                  {row.id}
                </TableCell>
                <TableCell key={row.child.firstName + index}>
                  {row.child.firstName}
                </TableCell>
                <TableCell key={row.child.lastName + index}>
                  {row.child.lastName}
                </TableCell>
                <TableCell key={row.date + index}>
                  {row.date}
                </TableCell>

              <ActionCell
                deleteEndpoint={`${API.HOST}api/attributes/${row.id}`}
                payload={{data: row}}
                callback={() => setRetry(!retry)}
              >
                <Tooltip title="Approved Report" arrow>

                  <IconButton
                    onClick={async (e) => showReport(row.child)}
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter
                      },
                      color: theme.palette.primary.main
                    }}
                    color="inherit"
                    size="small"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M15 14.25C13.3431 14.25 12 15.5931 12 17.25C12 18.9069 13.3431 20.25 15 20.25C16.6569 20.25 18 18.9069 18 17.25C18 15.5931 16.6569 14.25 15 14.25ZM10.5 17.25C10.5 14.7647 12.5147 12.75 15 12.75C17.4853 12.75 19.5 14.7647 19.5 17.25C19.5 19.7353 17.4853 21.75 15 21.75C12.5147 21.75 10.5 19.7353 10.5 17.25Z"
                            fill="#080341" />
                      <path fillRule="evenodd" clipRule="evenodd"
d="M15.75 8.25H8.25V6.75H15.75V8.25Z"
                            fill="#080341" />
                      <path fillRule="evenodd" clipRule="evenodd"
d="M15.75 11.25H8.25V9.75H15.75V11.25Z"
                            fill="#080341" />
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M5.25 3H16.0607L18.75 5.68934V12H17.25V6.31066L15.4393 4.5H6.75V19.5H9.75V21H5.25V3Z"
                            fill="#080341" />
                      <path fillRule="evenodd" clipRule="evenodd"
d="M9.75 14.25H8.25V12.75H9.75V14.25Z"
                            fill="#080341" />
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M13.5791 16.0854L14.9207 15.4146L15.4634 16.5H16.4999V18H14.5364L13.5791 16.0854Z"
                            fill="#080341" />
                    </svg>
                  </IconButton>
                </Tooltip>
              </ActionCell>
              </TableRow>
            )
          })}


        </DynamicTable>

      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  className: PropTypes.string,
  cryptoOrders: PropTypes.array.isRequired
};
export default RecentOrdersTable;
