import _ from 'lodash';

export const isItemInLocalStorage = (key: string): boolean => {
  const item = localStorage.getItem(key);
  return item !== null;
};

export const descendingComparator = <T>(a: T, b: T, orderBy: string) => {
  const prev = _.get(a,orderBy);
  const next = _.get(b,orderBy);
  console.log(prev,next);
  if (prev < next) {
    return -1;
  }
  if (prev > next) {
    return 1;
  }
  return 0;
};
