import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { API } from '../../config/config';
import { Key } from '@mui/icons-material';
import {
  PrizetImageUpload,
  SimpleFileUploadResponse
} from '../PrizetImageUpload';
import { PrizetAttributeSelector } from '../PrizetAttributeSelector';
import attributesValue from '../../content/applications/AttributesValue';
import { PrizetImageAPI } from '../../content/applications/Product/RecentOrdersTable';

export interface DropdownOptionsProps {
  value: string;
  label: string;
}

interface HttpFieldsProps {
  type:
    | 'text'
    | 'select'
    | 'checkbox'
    | 'password'
    | 'phone'
    | 'email'
    | 'number'
    | 'tel'
    | 'url'
    | 'search'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'textarea'
    | 'tag'
    | 'color'
    | 'selector'
    | 'image';
  label?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  selectOptions?: [DropdownOptionsProps];
  onChange?: (e: React.ChangeEvent<any>) => void;
  onImageUploadCallback?: (payload: PrizetImageAPI[]) => void;
  value: string;
  tagOptions?: string[];
  tagValue?: string[];
  children?: HttpFieldsProps[];
  onAttributeEvent?: (event: any) => void;
  options?: any;
  tagLabel?: string;
}

interface HttpLayoutProps {
  type: 'row' | 'column';
  children: HttpFieldsProps[] | any[];
}

interface HttpProps {
  fields: HttpFieldsProps[] | any[];
  baseUrl: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers: any; // lol
  openModal: boolean;
  onClose: () => void;
  title: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onSubmitCallback: (response: Response) => void;
  width?: number;
  submitText?: string;
  error?: string;
}

export const HttpModal = (props: HttpProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    onSubmitCallback,
    title,
    onClose,
    fields,
    baseUrl,
    method,
    headers,
    openModal,
    maxWidth = 'md',
    width,
    submitText,
    error
  } = props;

  const getFieldFromType = (
    field: HttpFieldsProps | HttpLayoutProps,
    index: number
  ): JSX.Element => {
    switch (field.type) {
      case 'image':
        return (
          <Box key={index}>
            <PrizetImageUpload
              onImageUploadCallback={field.onImageUploadCallback}
            />
          </Box>
        );

      case 'tag':
        return (
          <Autocomplete
            key={index}
            multiple
            id="tags-standard"
            options={field.tagOptions}
            getOptionLabel={(option) => option[field.tagLabel]}
            onChange={field.onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={field.label}
                placeholder={field.placeholder}
                value={''}
              />
            )}
          />
        );

      case 'row':
        return (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            columnGap={2}
            marginBottom={'3%'}
            alignItems="flex-start"
          >
            {field.children.map((child, index) =>
              getFieldFromType(child, index)
            )}
          </Box>
        );

      case 'column':
        return (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            rowGap={2}
            alignItems="flex-start"
          >
            {field.children.map((child, index) => {
              console.log(child);
              return getFieldFromType(child, index);
            })}
          </Box>
        );

      case 'select':
        return (
          <>
            <InputLabel id="label">{field.label}</InputLabel>
            <Select
              placeholder={field.placeholder}
              onChange={field.onChange}
              labelId="label"
              id="select"
              fullWidth={true}
              value={field.value}
              key={index}
            >
              {field.selectOptions?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </>
        );

      case 'selector':
        return (
          <PrizetAttributeSelector
            key={index}
            onAttributeEvent={field.onAttributeEvent}
            label={field.label}
            name={field.name}
            value={field.value}
            options={field.options}
          />
        );

      case 'checkbox':
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={field.value}
                onChange={field.onChange}
                inputProps={{
                  'aria-label': field.label
                }}
              />
            }
            label={field.label}
          />
        );

      case 'textarea':
        return (
          <TextField
            key={index}
            type={field.type}
            label={field.label}
            placeholder={field.placeholder}
            name={field.name}
            required={field.required}
            disabled={field.disabled}
            onChange={field.onChange}
            value={field.value}
            fullWidth={true}
            multiline
            rows={4}
          />
        );

      case 'text':
      case 'password':
      case 'phone':
        return (
          <TextField
            key={index}
            type={field.type}
            label={field.label}
            placeholder={field.placeholder}
            name={field.name}
            required={field.required}
            disabled={field.disabled}
            onChange={field.onChange}
            value={field.value}
            sx={{ width: '100%' }}
          />
        );
    }
  };

  const onSubmit = () => {
    setIsLoading(true);

    const flatFields = fields.map((field: any) => {
      if (field.children) {
        let newMap = [];
        field.children.map((child: any) => {
          newMap.push({
            [child.name]: child.value
          });
        });

        return Object.assign(
          {},
          newMap.reduce((a, b) => Object.assign(a, b), {})
        );
      }
      if (!field.children) {
        return {
          [field.name]: field.value
        };
      }
    });

    // Convert flatfield to object
    // @ts-ignore

    fetch(`${baseUrl}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(
        Object.assign(
          {},
          flatFields.reduce((a, b) => Object.assign(a, b), {})
        )
      )
    }).then(
      (response) => {
        onSubmitCallback(response);
      },
      (error) => {
        onSubmitCallback(error);
      }
    );

    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      maxWidth={maxWidth}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          width: width ? width : 'auto'
        }}
      >
        {error && (
          <p
            className={'capitalize-first-letter'}
            style={{
              color: 'red',
              background: '#ffeaea',
              padding: '2% 4%',

              border: '1px solid #febdbd',
              marginBottom: '7%',
              borderRadius: '3px'
            }}
          >
            {error}
          </p>
        )}
        {fields.map((field: HttpFieldsProps | HttpLayoutProps, index: number) =>
          getFieldFromType(field, index)
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          autoFocus
          onClick={(e) => {
            onSubmit();
          }}
        >
          {isLoading ? (
            <CircularProgress size={6} />
          ) : (
            submitText ?? 'Save changes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
