import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { PrizetImageAPI } from '../content/applications/Product/RecentOrdersTable';

export interface SimpleFileUploadResponse {
  data: Data;
}

export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  'cdn-url': string;
  'file-size': number;
  filename: string;
  'created-at': string;
  tag: Tag;
}

export interface Tag {
  id: any;
  name: any;
}


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});


export type ImageUploadProps = {
  onImageUploadCallback: (payload: PrizetImageAPI[]) => void;
}

export const PrizetImageUpload = (props: ImageUploadProps) => {
  const [imageList, setImageList] = useState<PrizetImageAPI[]>([]);

  useEffect(() => {
    props.onImageUploadCallback(imageList);
  }, [imageList]);

  const UploadFile = (fileName: string) => {
    const imageData = [{
      image: fileName,
      isThumbnail:false,
      placeholder: 'Image'

    }];



    setImageList(imageData);
    props.onImageUploadCallback(imageList);


  };

  return (
    <div>


      <Button
        component="label"
        role={'button'}
        variant="contained"
        tabIndex={-1}
        style={{
          marginBottom: '5%'
        }}
        startIcon={<CloudUploadIcon />}
      >

        <TextField id="standard-basic" onChange={e => UploadFile(e.target.value)} label="Standard" variant="standard" />
      </Button>
      <Box
        display={'flex'}
        flexDirection={'row'}
        columnGap={2}
      >
        {imageList.map((image, index) => {
          return (
            <ImgMediaCard image={image} key={index} />
          );
        })}

      </Box>

    </div>

  );

};

export default function ImgMediaCard({ image }: { image: PrizetImageAPI }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image={image.image}
      />
      <CardContent>
        <Box>
          <FormGroup>
            <FormControlLabel control={
              <Checkbox
                onChange={e => image.isThumbnail = e.target.checked}
                checked={image.isThumbnail} defaultChecked />} label="Is Thumbnail ?" />
          </FormGroup>
        </Box>
        <Box>
          <TextField
            defaultValue={image.placeholder}
            onChange={(e) => {
              image.placeholder = e.target.value;
            }}
            multiline={true}
            fullWidth={true}
          />
        </Box>
      </CardContent>

    </Card>
  );
}
