import React from "react";
import "./table.css";
import {
  Box,
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TableSortLabel,
  TextField,
  Tooltip,
  useTheme
} from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useDeleteModalStore } from '../../zustandStore/store.delete';
import { Delete, SearchRounded } from '@mui/icons-material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';


type header = {
  displayName: string;
  key: string;
}

type rows = {
  key: string;
  compute: (data: any) => JSX.Element
}

export interface DynamicTableProps {
  children: React.ReactNode;
  headers?: header[];
  searchElement?: ReactJSXElement;
  sort?: 'asc' | 'desc';
  onSort?: (header: string) => void;
  sortByField?: string;
}

export const DynamicTable: React.FC<any> = (props: DynamicTableProps) => {
  const { headers, searchElement,sort = 'desc', onSort, sortByField  } = props;

  return (
    <>
      {searchElement}
      <Table>
        <TableHead>
          <TableRow>
            {headers?.map((header, index) => {
              return (
                <TableCell key={index}>
                  <TableSortLabel
                    active={sortByField === header.key}
                    direction={sortByField === header.key ? sort : 'asc'}
                    onClick={e =>
                      onSort ? onSort(header.key) :
                        ('')
                    }
                  >
                    {header.displayName}
                  </TableSortLabel>
                </TableCell>
              );
            })}
            <TableCell key={'action'}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.children}
        </TableBody>
      </Table>
    </>
  );
};

export interface ActionCellProps<T>  {
  editEndpoint?: string;
  deleteEndpoint?:string;
  payload?: T;
  callback?: () => void;
  updateFields?: any;
  children?: React.ReactNode;
}

export const ActionCell = (props: ActionCellProps<any>) => {
  const actions = useDeleteModalStore((state) => state.actions);

  const theme = useTheme();
  return (
    <TableCell align="right">
      {props.children}
      <Tooltip title="Edit Order" arrow>

        <IconButton
          onClick={e => actions.setModal({
            modalOpen: true,
            payload: props.payload,
            callback: props.callback,
            editEndpoint: props.editEndpoint,
            isEdit: true,
            isDelete: false,
            updateFields: props.updateFields,
          })}
          sx={{
            "&:hover": {
              background: theme.colors.primary.lighter,
            },
            color: theme.palette.primary.main,
          }}
          color="inherit"
          size="small"
        >
          <EditTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Order" arrow>
        <IconButton
          onClick={e => actions.setModal({
            modalOpen: true,
            payload: props.payload,
            callback: props.callback,
            deleteEndpoint: props.deleteEndpoint,
            isDelete: true,
            isEdit: false,
          })}
          sx={{
            "&:hover": { background: theme.colors.error.lighter },
            color: theme.palette.error.main,
          }}
          color="inherit"
          size="small"
        >
          <DeleteTwoToneIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </TableCell>
  );

};

export interface TableSearchProps {
  searchData:Array<any>;
  setSearchData: (data: any) => void;
  placeholder?: string;
}

export const TableSearch = (props: TableSearchProps) => {

  const {setSearchData, searchData} = props;

  const onHandleSearch = (e: any) => {
    // Handle the search and return result

    debounce(() => {
      const _search =(row: any) => {
        if(!row) return false;
        // Search the values
        if(typeof row !== 'object'){
          return row.toString().toLowerCase().includes(e.target.value.toLowerCase());
        }else{
          return Object.values(row).filter(_search).length > 0;
        }

      }
      const result = Object.values(searchData).filter(_search);
      console.log(result);
      setSearchData(result);

    },1000)();

  }

  return (
    <Box display={"flex"} padding={"2%"}>
      <TextField
        onInput={e => onHandleSearch(e)}
        id="outlined-start-adornment"
        sx={{ m: 1, width: "100%" }}
        placeholder={props.placeholder}
        InputProps={{
          startAdornment: <InputAdornment position="start">
            <SearchRounded />
          </InputAdornment>,
        }}
      />
    </Box>
  );
};


export const DeleteDialog = () => {
  const state = useDeleteModalStore((state) => state);

  const onAccept = async () => {

  };
  return (
    <React.Fragment>
      <Dialog
        open={state.settings.modalOpen && state.settings.isDelete}
        onClose={e => state.actions.setModal({ modalOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Delete></Delete>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this record ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => state.actions.setModal({ modalOpen: false })}>Cancel</Button>
          <Button onClick={async (e) => {
            await onAccept();
          }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
