import { create } from 'zustand';

interface DeleteStore {
  modalOpen: boolean;
  deleteEndpoint?: string;
  payload?: any;
  editEndpoint?: string;
  callback?: () => void;
  isEdit?: boolean;
  isDelete?: boolean;
  updateFields?: [];
}

let settings: DeleteStore = {
  modalOpen: false,
  deleteEndpoint: '',
  editEndpoint: '',
  payload: {},
  callback: () => {},
  isEdit: false,
  isDelete: false,
  updateFields: []
};

export interface DeleteState {
  actions: { setModal: (settings: DeleteStore) => void };
  settings: DeleteStore;
}

export const useDeleteModalStore = create<DeleteState>((set) => ({
  settings,
  actions: {
    setModal: (settings: DeleteStore) => {
      // @ts-ignore
      set((state) => ({
        settings: { ...state.settings, ...settings }
      }));
    }
  }
}));
